import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Клиенты',
  },
  table: {
    not_found: 'Клиенты не найдены',

    title: 'Клиенты',

    column: {
      id: 'ID',
      idOneC: 'ID 1С',
      title: 'Название',
      manager: 'Менеджер',
      representatives: 'Представители',
      createdAt: 'Создан',
      updatedAt: 'Изменён',
    },
  },
  form: {},
};

export const t = ft(ru, 'clients.');
