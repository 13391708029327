<template>
  <div
    :id="field.id"
    class="form-editor"
  />
</template>

<script lang="ts" setup>
import './editor.css';
import {
  defineProps,
  PropType,
  ref,
  watchEffect,
  defineEmits, onMounted,
} from 'vue';
import { magnerMessage } from 'magner';
import { useTranslate, type EditorField } from 'magner';
import type { OutputData, API as EditorApi } from '@editorjs/editorjs';
import { setupEditor } from './setup';

const props = defineProps({
  field: {
    type: Object as PropType<EditorField<any>['props']>,
    required: true,
  },
  modelValue: {
    type: [String],
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);
const { customT } = useTranslate();

const val = ref<string>(props.modelValue);
watchEffect(() => {
  val.value = props.modelValue;
});

const changeVal = (newVal: string) => {
  val.value = newVal;
  emit('update:modelValue', newVal);
};

let parsedData: OutputData;

try {
  parsedData = JSON.parse(val.value);
} catch (_) {
  parsedData = { blocks: [] };
}

onMounted(() => {
  setTimeout(() => {
    setupEditor({
      holder: props.field.id,
      placeholder: customT(props.field.placeholder || ''),
      data: parsedData,
      readOnly: false,
      onChange: async (editor: EditorApi, block: CustomEvent) => {
        const { blocks } = await editor.saver.save();

        const metaLength = blocks.filter((item) => item.type === 'meta');

        if (blocks.at(-1) && metaLength.length > 1) {
          const removeIndex = blocks.findIndex((item) => item.id === block.detail.target.id);

          await editor.blocks.delete(removeIndex);

          magnerMessage({
            type: 'error',
            message: 'Meta description не может быть больше одного',
          });
        }

        editor.saver?.save?.().then((outputData) => {
          changeVal(JSON.stringify(outputData));
        });
      },
    });
  }, 500);
});
</script>
