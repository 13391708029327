import { ft } from '~/utils/feature-translate';

export const ru = {
  sidebar: {
    table_title: 'Пользователи',
    card_title: 'Новый пользователь',
  },
  table: {
    title: 'Пользователи',
    not_found: 'Пользователи не найдены',
    remove_many: 'Удалить выбранное',
    search: 'Поиск',

    email: 'Е-мейл',
    phone: 'Номер телефона',
    name: 'Имя',
    avatar: 'Аватар',
    addresses: 'Адреса',
    companies: 'Компании',
    idOneC: 'ID 1С',
    activated: 'Активирован',
    deleted: 'Удален',
    created_at: 'Дата регистрации',
  },
  filters: {
    email: 'Е-мейл',
    phone: 'Номер телефона',
    name: 'Имя',
  },
  form: {
    active: 'Активен',
    title: 'Пользователь',
    tabs: {
      user: 'Пользователь',
      companies: 'Компании',
    },

    confirm_delete: 'Вы уверены, что хотите удалить данного пользователя?',
    name: { label: 'Имя', placeholder: 'Иван Васильевич' },
    phone: { label: 'Номер телефона', placeholder: '+7-999-999-99-99' },
    added_phone: { label: 'Добавочный номер', placeholder: '' },
    email: { label: 'Электронная почта', placeholder: 'example{\'@\'}domain.com' },
    password: { label: 'Пароль', placeholder: '' },
    birthdate: { label: 'Дата рождения', placeholder: '26.06.1980' },
    legals: { label: 'Компании', placeholder: 'Выбрать' },
    is_public_manager: { label: 'Выводить в список менеджеров' },
    addresses: {
      title: 'Адреса',
      add: 'Добавить',
      placeholder: 'г. Москва, ул. Ленина, д. 1',
    },
    roles: {
      label: 'Роль пользователя',
      placeholder: 'Выбрать',
      admin: 'Администратор',
      manager: 'Менеджер',
      user: 'Пользователь',
      super_manager: 'Менеджер с доступом',
      sales_chief: 'Руководитель отдела продаж',
      admin_manager: 'Контент-менеджер',
    },
    avatar: { label: 'Аватар' },
    companies_title: 'Компании',
    idOneC_title: 'ID 1С',
    departmentTitle_title: 'Отдел',
    supervisorName_title: 'Руководитель',
    updatedInAdminPanelAt_title: 'Время последнего обновления через Админ-панель',
    updatedByAPICallAt_title: 'Время последнего обновления через API (данными 1С)',
  },
};

export const t = ft(ru, 'users.user.');
