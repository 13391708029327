import { CategoryEntity } from 'features/catalog/categories';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import type { Entity, BatchRequestData } from './types';
import { formatShortDate, formatDate } from '~/utils/format-date';

const BASE = '/api/admin/product';

export const read = request.table<Entity>(async ({ data }) => {
  const activePage = data.pagination.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  const search = data.filters.title ? `&filters[0][id]=search&filters[0][value]=${data.filters.title}` : '';
  const supplier = data.filters.supplier ? `&filters[1][id]=supplier&filters[1][value]=${data.filters.supplier}` : '';
  const brand = data.filters.brand ? `&filters[2][id]=brand&filters[2][value]=${data.filters.brand}` : '';
  const category = data.filters.category ? `&filters[3][id]=category&filters[3][value]=${data.filters.category}` : '';

  const sortTitle = data.sort.title ? `&sort[0][id]=title&sort[0][value]=${data.sort.title}` : '';
  const sortSku = data.sort.sku ? `&sort[0][id]=sku&sort[0][value]=${data.sort.sku}` : '';
  const sortBrand = data.sort.brand ? `&sort[0][id]=brand&sort[0][value]=${data.sort.brand}` : '';
  const sortSupplier = data.sort.supplier ? `&sort[0][id]=supplier&sort[0][value]=${data.sort.supplier}` : '';

  return getListResponse<Entity>(`${BASE}/list?${pagination}${supplier}${brand}${category}${search}${sortTitle}${sortSku}${sortBrand}${sortSupplier}`, activePage, data.filters.active);
});

export const search = request.custom<Entity[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<Entity>(`${BASE}/list?${pagination}`);
  return { data: res.data.rows };
});

export const get = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Entity }>(`${BASE}/read/${data.id}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }

  let description = res.data?.data?.description;

  try {
    JSON.parse(description);
  } catch (_) {
    description = JSON.stringify({ 
      time: Date.now(), 
      blocks: [
        { 
          id: 'default', 
          type: 'paragraph', 
          data: { 
            text: res.data?.data?.description,
          }, 
        },
      ], 
    });
  }

  return {
    data: {
      ...res.data?.data,
      images: res.data.data?.images.map((item) => ({
        ...item,
        downloadUrl: item.full,
      })),
      propertyValues: res.data.data?.propertyValues?.map((item) => ({
        ...item,
        id: item.property.id,
      })),
      activeTitle: res.data?.data?.active ? 'Активен' : 'Не активен',
      causeOfDeactivation: res.data?.data?.causeOfDeactivation ? res.data?.data?.causeOfDeactivation : 'Не известно',
      categories: Array.isArray(res.data?.data?.categories) ? res.data?.data?.categories.map((item: CategoryEntity) => item.id) : null,
      category: res.data?.data?.category ? res.data?.data?.category.id : null,
      createdAt: res.data?.data.createdAt ? formatShortDate(res.data?.data.createdAt) : 'Не известно',
      lastImportedAt: res.data?.data.lastImportedAt ? formatDate(res.data?.data.lastImportedAt) : 'Не известно',
      description,
    },
  };
});

export const create = request.card<Entity, Entity>(async ({
  api, data, parseError, router,
}) => {
  filterRequestStrings(data.data);

  const body = {
    ...data.data,
    variations: [],
    active: true,
  };

  if (!data.data.discount?.value) {
    body.discount = null;
  }

  if (data.data?.propertyValues?.length) {
    body.propertyValues = data.data.propertyValues.filter((item) => item.id || item.value);

    if (body.propertyValues.some((item) => !item.id || !item.value)) {
      return { error: { message: 'Заполните все поля деталей товара' } };
    }
  }

  const res = await api.post<{ data: Entity }>(`${BASE}/create`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  router.push({ name: 'product', params: { id: res.data?.data?.id } });
  return { data: res.data?.data };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  filterRequestStrings(data.data);

  // TODO 26 июля. Fedor
  // Какой-то баг в самом magner и приходиться писать этот фикс
  // Потому что посылается объект при обновлении а не id как мы хотим
  if (data.data.brand?.id) {
    // @ts-ignore
    data.data.brand = data.data.brand.id;
  }
  if (data.data.category?.id) {
    // @ts-ignore
    data.data.category = data.data.category.id;
  }
  if (data.data.supplier?.id) {
    // @ts-ignore
    data.data.supplier = data.data.supplier.id;
  }
  if (data.data?.images?.some?.((item) => item.id)) {
    // @ts-ignore
    data.data.images = data.data.images.map((item) => item.id);
  }

  if (!data.data.discount?.value) {
    data.data.discount = null;
  }

  if (data.data?.propertyValues?.length) {
    data.data.propertyValues = data.data.propertyValues.filter((item) => item.id || item.value);

    if (data.data.propertyValues.some((item) => !item.id || !item.value)) {
      return { error: { message: 'Заполните все поля деталей товара' } };
    }
  }

  const res = await api.patch<{ data: Entity }>(`${BASE}/update/${data.id}`, data.data);
  if (res.error) {
    return { error: parseError(res.error) };
  }
  return { data: res.data?.data };
});

export const batch = request.custom<boolean, BatchRequestData>(async ({ data, api, parseError }) => {
  const res = await api.post(`${BASE}/batch/${data.type}`, { ids: data.ids });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: true,
  };
});

export const remove = request.card<boolean, Entity>(async ({ data }) => batch({
  ids: [data.id as string],
  type: 'delete',
}));

export const setSecondCategoryToManyProducts = request.custom<Entity>(async ({ data, parseError }) => {
  const res = await request.api.post<{ data: { items: Entity[] } }>(`${BASE}/batch-category`, { ...data });

  if (res.data) {
    return { data: res.data };
  }

  return { error: parseError(res.error) };
});
