import { request } from '~/utils/request';
import type { Entity, SupportedPages } from './types';
import {
  fakeData,
  getTableData,
  seoDynamicPages,
  seoStaticPages,
} from './mocks';
import { ru } from './ru';

const BASE = '/api/admin/seo';

export const readStaticTable = request.table<Entity>(async () => ({
  data: {
    rows: getTableData(seoStaticPages),
    pagination: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 1,
    },
  },
}));

export const readDynamicTable = request.table<Entity>(async () => ({
  data: {
    rows: getTableData(seoDynamicPages),
    pagination: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 1,
    },
  },
}));

export const get = request.card<Entity, Entity>(async ({ api, data }) => {
  const res = await api.get<{ data: { elements: Entity[] }}>(`${BASE}/list`);
  const entity: Entity = res.data?.data.elements.find((item) => item.slug === data.id) || fakeData;

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');
    const text = ru.sidebar.title[data.id as SupportedPages];
    if (header && text) {
      header.innerHTML = text;
    }
  }, 0);

  return { data: entity };
});

export const update = request.card<Entity, Entity>(async ({ api, data, parseError }) => {
  const payload = {
    slug: data.id,
    title: data.data.title,
    keywords: data.data.keywords,
    description: data.data.description,
    h1: data.data.h1,
    h2: data.data.h2,
  };

  const res = await api.post<{ data: Entity }>(`${BASE}/set`, payload);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const remove = request.card<boolean, Entity>(async () => ({
  data: true,
}));
