import { cardPageController } from 'magner';
import { saveImageContext } from 'features/common/file-request';
import { baseT } from 'configs/translation/base';
import type { UserCreate } from './types';
import {
  get,
  create,
  update,
  remove,
} from './requests';
import { t } from './ru';
import './users.css';
import { USER_ROLES } from './types';
import { formatDate } from '~/utils/format-date';

export const cardConfig = cardPageController<UserCreate>({
  header: {
    title: t('form.title'),
    tabs: [
      {
        label: t('form.tabs.user'),
        link: { name: 'user-companies' },
        active: true,
      },
      {
        label: t('form.tabs.companies'),
        link: { name: 'user-companies' },
        active: false,
      },
    ],
  },

  getRequest: get,
  createRequest: create,
  updateRequest: update,
  deleteRequest: remove,

  confirmDelete: t('form.confirm_delete'),

  form: {
    actions: [
      {
        type: 'link',
        to: { name: 'users' },
        props: {
          text: baseT('form.cancel_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: baseT('form.remove_button'),
          type: 'danger',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** NAME */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: t('form.name.label'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: t('form.name.placeholder'),
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'blur',
                  }],
                },

                {
                  type: 'input',
                  name: 'phone',
                  label: t('form.phone.label'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.phone.placeholder'),
                    mask: {
                      mask: '+7-###-###-##-##',
                    },
                    disabled: ({ state }) => !!state.idOneC,
                  },
                },

                {
                  type: 'input',
                  name: 'additionalPhone',
                  label: t('form.added_phone.label'),
                  dataType: 'number',
                  props: {
                    placeholder: t('form.added_phone.placeholder'),
                    type: 'number',
                    disabled: ({ state }) => !!state.idOneC,
                  },
                },

                {
                  type: 'input',
                  name: 'email',
                  label: t('form.email.label'),
                  props: {
                    required: true,
                    type: 'email',
                    autocomplete: 'chrome-off',
                    placeholder: t('form.email.placeholder'),
                    disabled: ({ state }) => !!state.idOneC,
                  },
                  validation: [{
                    type: 'empty-required' as 'empty',
                    trigger: 'blur',
                  }],
                },

                {
                  type: 'input',
                  name: 'password',
                  label: t('form.password.label'),
                  props: {
                    type: 'text',
                    autocomplete: 'off',
                    placeholder: t('form.password.placeholder'),
                  },
                },

                {
                  type: 'datetime',
                  dataType: 'date',
                  name: 'birthdate',
                  label: t('form.birthdate.label'),
                  props: {
                    type: 'date',
                    placeholder: t('form.birthdate.placeholder'),
                    format: 'DD.MM.YYYY',
                    valueFormat: 'YYYY-MM-DD',
                  },
                },

                {
                  type: 'select',
                  dataType: 'array',
                  name: 'roles',
                  label: t('form.roles.label'),
                  options: [
                    {
                      value: USER_ROLES.Admin,
                      label: t('form.roles.admin'),
                    },
                    {
                      value: USER_ROLES.Manager,
                      label: t('form.roles.manager'),
                    },
                    {
                      value: USER_ROLES.SuperManager,
                      label: t('form.roles.super_manager'),
                    },
                    {
                      value: USER_ROLES.SalesChief,
                      label: t('form.roles.sales_chief'),
                    },
                    {
                      value: USER_ROLES.User,
                      label: t('form.roles.user'),
                    },
                    {
                      value: USER_ROLES.AdminManager,
                      label: t('form.roles.admin_manager'),
                    },
                  ],
                  props: {
                    placeholder: t('form.roles.placeholder'),
                    multiple: true,
                  },
                  validation: [{
                    type: 'user-roles' as 'empty',
                    trigger: 'blur',
                  }],
                },
                {
                  type: 'switch',
                  name: 'isPublicManager',
                  dataType: 'boolean',
                  label: t('form.is_public_manager.label'),
                  props: {},
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** AVATAR and addresses */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'dropzone',
                  name: 'avatar',
                  label: t('form.avatar.label'),
                  props: {
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImageContext('userAvatar'),
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                  },
                },
              ],
            },

            {
              type: 'column',
              title: t('form.addresses.title'),
              props: { span: 24, isPaper: true, titleType: 'heading' },
              fields: [
                {
                  type: 'collection',
                  name: 'shipmentAddresses',
                  dataType: 'array',
                  props: {
                    class: 'collection-addresses',
                    showFirst: true,
                    firstRemovable: true,
                  },
                  layout: [
                    {
                      type: 'row',
                      props: { elementsGrow: true },
                      fields: [
                        {
                          type: 'custom',
                          name: 'id',
                          dataType: 'number',
                          component: () => null,
                          props: {},
                        },
                        {
                          type: 'input',
                          name: 'value',
                          props: {
                            placeholder: t('form.addresses.placeholder'),
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              props: {
                hidden: ({ isNew }) => isNew,
              },
              fields: [
                {
                  type: 'input',
                  name: 'idOneC',
                  label: t('form.idOneC_title'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'departmentTitle',
                  label: t('form.departmentTitle_title'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'supervisorName',
                  label: t('form.supervisorName_title'),
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'updatedInAdminPanelAt',
                  label: t('form.updatedInAdminPanelAt_title'),
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => (val ? formatDate(val) : ''),
                  },
                },
                {
                  type: 'input',
                  name: 'updatedByAPICallAt',
                  label: t('form.updatedByAPICallAt_title'),
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => (val ? formatDate(val) : ''),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
