import { supplierAllSearch } from 'features/catalog/supplier';
import {
  tablePageController,
  filtersFormController,
  tableController,
} from 'magner';
import type { Entity } from './types';
import { read } from './requests';
import { t } from './ru';
import { formatDate } from '~/utils/format-date';

import CategoryCascader from './components/products-category-cascader.vue';
import CategoryFilter from './components/category-filter.vue';
import SetManyCategoriesAction from './components/set-many-categories-action.vue';

const defaultSupplierId = localStorage.getItem('defaultSupplierId') ?? 1;

export const tableConfig = tablePageController<Entity>({
  header: {
    title: t('pages.table_title'),
  },

  request: read,

  table: tableController<Entity>({
    emptyText: t('table.not_found'),
    rowSelectable: {
      rowIdKey: 'id',
      customActions: [
        {
          emits: 'deselect-and-update',
          component: () => SetManyCategoriesAction,
        },
      ],
    },
    rowTree: {
      key: 'id',
      childrenListKey: 'products',
    },
    columns: [
      {
        prop: 'name',
        label: t('table.name'),
      },
      {
        prop: 'sku',
        label: t('table.sku'),
      },
      {
        prop: 'brand',
        label: t('table.brand'),
      },
      {
        prop: 'hierarchy',
        label: t('table.category_supplier'),
        view: {
          type: 'text',
          formatter: (_, row) => {
            if (row.hierarchy) {
              if (row.hierarchy?.length) {
                return row.hierarchy.join(' → ');
              }
              return '-';
            }
            return '↑';
          },
        },
      },
      {
        prop: 'lastImportedAt',
        label: t('table.last_imported_at'),
        view: {
          type: 'text',
          formatter: (_, row) => {
            if (row.hierarchy) {
              if (row.lastImportedAt) return formatDate(row.lastImportedAt);
              return '-';
            }
            return '↑';
          },
        },
      },
      {
        prop: 'category',
        label: t('table.categories'),
        view: {
          type: 'custom',
          component: () => CategoryCascader,
        },
      },
    ],
  }),

  filters: filtersFormController<Entity>({
    saveToLocalStorage: true,

    actions: [],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      supplierId: +defaultSupplierId,
      categoryId: null,
      name: '',
      unmappedOnly: null,
    },

    sort: {},

    layout: [
      {
        type: 'select',
        name: 'supplierId',
        dataType: 'string',
        options: [],
        changeAction: ({ form }) => {
          form.categoryId = '';
        },
        props: {
          filterable: true,
          remote: true,
          collapseTags: true,
          valueKey: 'id',
          labelKey: 'title',
          remoteMethod: supplierAllSearch,
          placeholder: t('table.supplier'),
        },
      },
      {
        type: 'custom',
        name: 'categoryId',
        dataType: 'string',
        component: () => CategoryFilter,
        props: {
          placeholder: t('table.category_filter'),
        },
      },
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
      {
        type: 'switch',
        name: 'unmappedOnly',
        dataType: 'boolean',
        props: {
          activeLabel: t('table.unmapped_only_filter'),
        },
      },
    ],
  }),
});
