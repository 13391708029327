import {
  actionTable, filtersFormController, tableController, tablePageController,
} from 'magner';
import { baseT } from 'configs/translation/base';
import { formatShortDate } from '~/utils/format-date';
import type { UserList } from './types';
import { read, batch } from './requests';
import { t } from './ru';

export const tableConfig = tablePageController<UserList>({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<UserList>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,

    rowSelectable: {
      rowIdKey: 'id',
      actions: [
        {
          type: 'action',
          emits: 'deselect-and-update',
          action: actionTable<UserList>(async ({ data }) => {
            const ids = data.selected.map((item) => item.id);
            const res = await batch({ ids });
            return !!res.error;
          }),
          props: {
            text: t('table.remove_many'),
            type: 'danger',
          },
        },
      ],
    },
    emptyText: t('table.not_found'),
    rowLink: (row) => (
      {
        name: 'user',
        params: { id: row.id },
      }
    ),
    columns: [
      {
        prop: 'email',
        width: 200,
        label: t('table.email'),
      },
      {
        prop: 'phone',
        width: 150,
        label: t('table.phone'),
      },
      {
        prop: 'name',
        width: 150,
        label: t('table.name'),
      },
      {
        prop: 'created_at',
        width: 200,
        label: t('table.created_at'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (_, row) => formatShortDate(row.created_at?.date),
        },
      },
      {
        prop: 'avatar',
        width: 150,
        label: t('table.avatar'),
        view: {
          type: 'image',
          formatter: (_, row) => row.avatar || '/empty.png',
        },
      },
      {
        prop: 'companies',
        label: t('table.companies'),
        showOverflowTooltip: true,
        width: 300,
        view: {
          type: 'text',
          formatter: (_, row) => row.companies.join('; '),
        },
      },
      {
        prop: 'idOneC',
        label: t('table.idOneC'),
      },
      {
        prop: 'activated',
        label: t('table.activated'),
        width: 120,
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
      {
        prop: 'deleted',
        label: t('table.deleted'),
        view: {
          type: 'text',
          formatter: (cellValue) => (cellValue ? 'Да' : 'Нет'),
        },
      },
    ],
  }),
  filters: filtersFormController<UserList>({
    saveToLocalStorage: true,

    actions: [
      {
        type: 'link',
        to: { name: 'user', params: { id: 'new' } },
        props: {
          type: 'primary',
          text: baseT('form.create_new_button'),
        },
      },
    ],

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      name: '',
      activated: null,
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'name',
        props: {
          placeholder: t('table.search'),
          inputDelay: 400,
        },
      },
      {
        type: 'switch',
        name: 'activated',
        dataType: 'boolean',
        props: {
          activeLabel: baseT('filters.active'),
        },
      },
    ],
  }),
});
