import {
  filtersFormController,
  tableController,
  tablePageController,
} from 'magner';
import type { ClientList, ClientRepresentative, ClientManager } from './types';
import { read } from './requests';
import { t } from './ru';
import { formatDate } from '~/utils/format-date';

const getRepresentativesStr = (representatives: Array<ClientRepresentative>) => {
  const names: Array<string> = representatives
    .map((r: ClientRepresentative) => r.name)
    .slice(0, 3);
  if (representatives.length > 3) {
    names.push('...');
  }
  return names.join(', ');
};

export const tableConfig = tablePageController<ClientList>({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<ClientList>({
    tableLayout: 'auto',

    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'id',
        label: t('table.column.id'),
        sortable: true,
        width: 80,
      },
      {
        prop: 'idOneC',
        label: t('table.column.idOneC'),
        sortable: true,
        width: 200,
      },
      {
        prop: 'title',
        label: t('table.column.title'),
        sortable: true,
        width: 250,
      },
      {
        prop: 'manager',
        label: t('table.column.manager'),
        width: 200,
        view: {
          type: 'html',
          formatter: (value) => (value ? (value as ClientManager).name : '&ndash;'),
        },
      },
      {
        prop: 'representatives',
        label: t('table.column.representatives'),
        width: 450,
        view: {
          type: 'html',
          formatter: (value) => getRepresentativesStr(value as Array<ClientRepresentative>),
        },
      },
      {
        prop: 'createdAt',
        label: t('table.column.createdAt'),
        sortable: true,
        width: 160,
        view: {
          type: 'html',
          formatter: (value) => formatDate(value as Date),
        },
      },
      {
        prop: 'updatedAt',
        label: t('table.column.updatedAt'),
        sortable: true,
        width: 160,
        view: {
          type: 'html',
          formatter: (value) => formatDate(value as Date),
        },
      },
    ],
  }),
  filters: filtersFormController<ClientList>({
    saveToLocalStorage: true,

    submitEvent: 'input',

    pagination: {
      items: 25,
      page: 1,
    },

    filtersData: {
      title: '',
    },

    sort: {},

    layout: [
      {
        type: 'input',
        name: 'title',
        props: {
          inputDelay: 400,
        },
      },
    ],
  }),
});
