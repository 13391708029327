import { routerController, translate } from 'magner';
import loginPageConfig from 'features/login/login';

import { userTableConfig, userCardConfig, USER_ROLES } from 'features/users/users';
import { clientTableConfig } from 'features/clients';
import { legalPersonTableConfig, legalPersonCardConfig } from 'features/users/legal-persons';

import { orderTableConfig } from 'features/orders';

import { promotionsCardConfig, promotionsTableConfig } from 'features/content/promotions';
import { newsCardConfig, newsTableConfig } from 'features/content/news';
import { blogCardConfig, blogTableConfig } from 'features/content/blog';
import { requirementsCardConfig, requirementsTableConfig } from 'features/content/requirements';
import { contentPageRoutes, deliveryPageRoutes } from 'features/content/pages';
import { orderInfoCardConfig } from 'features/content/order-info';
import { contactsPageRoutes } from 'features/contacts';

import {
  seoStaticTableConfig,
  seoStaticCardConfig,
  seoDynamicCardConfig,
  seoDynamicTableConfig,
} from 'features/seo';
import { bannerTableConfig, bannerCardConfig } from 'features/settings/banners';
import { sliderTableConfig, sliderCardConfig } from 'features/settings/slider';
import { footerCategoriesCardConfig } from 'features/settings/footer-categories';
import { settingsImagesConfig } from 'features/settings/images';

import { productCardConfig, productTableConfig } from 'features/catalog/products';
import { variationCardConfig, variationTableConfig } from 'features/catalog/products/variations';
import { categoryTableConfig, categoryCardConfig } from 'features/catalog/categories';
import { brandTableConfig, brandCardConfig } from 'features/catalog/brands';

import { productImportTableConfig } from 'features/catalog-import/products';
import { categoryImportTableConfig } from 'features/catalog-import/categories';
import { brandImportTableConfig } from 'features/catalog-import/brands';

import { exportsTableConfig } from 'features/exports';
import { minPriceOrderConfig } from 'features/settings/min-price-order';
import { pricesConfig } from 'features/settings/prices';

import { baseColorsCardConfig, baseColorsTableConfig } from 'features/mappings/base-colors';
import { baseMaterialsCardConfig, baseMaterialsTableConfig } from 'features/mappings/base-materials';
import { propertiesTableConfig } from 'features/mappings/properties';

const router = routerController<USER_ROLES>({
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'products',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('./empty.vue'),
        roles: false,
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/icons/logo.svg'),
          headerCollapsedIcon: () => import('assets/icons/tmg-small.svg'),
          sidebarGroups: [
            {
              name: 'catalog',
              icon: () => import('assets/icons/catalog.svg'),
              title: translate('base.sidebar.catalog_group'),
              routes: ['products', 'categories', 'brands'],
            },
            {
              name: 'catalog-import',
              icon: () => import('assets/icons/catalog.svg'),
              title: translate('base.sidebar.catalog_import_group'),
              routes: ['products-import', 'categories-import', 'brands-import'],
            },
            {
              name: 'mappings',
              icon: () => import('assets/icons/mappings.svg'),
              title: translate('base.sidebar.mappings_group'),
              routes: ['base-colors', 'base-materials', 'mappings-properties'],
            },
            {
              name: 'content-group',
              icon: () => import('assets/icons/book-open.svg'),
              title: translate('base.sidebar.content_group'),
              routes: [
                'promotions',
                'news',
                'blog',
                'requirements',
                ...contentPageRoutes.sidebarGroup,
              ],
            },
            {
              name: 'contacts-group',
              icon: () => import('assets/icons/phone.svg'),
              title: translate('base.sidebar.contacts_group'),
              routes: [...contactsPageRoutes.sidebarGroup],
            },
            {
              name: 'delivery-group',
              icon: () => import('assets/icons/truck.svg'),
              title: translate('base.sidebar.delivery_group'),
              routes: [...deliveryPageRoutes.sidebarGroup],
            },
            {
              name: 'order-info-group',
              icon: () => import('assets/icons/info.svg'),
              title: translate('base.sidebar.order_info_group'),
              routes: ['order-info', 'order-info-msk'],
            },
            {
              name: 'seo-group',
              icon: () => import('assets/icons/search.svg'),
              title: translate('base.sidebar.seo_group'),
              routes: ['seo-static', 'seo-dynamic'],
            },
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('base.sidebar.settings_group'),
              routes: ['banners', 'slider', 'footer-categories', 'images', 'min-price-order', 'prices'],
            },
          ],
        },

        routes: [
          /** BLOCK: USERS */

          /** Users */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: userTableConfig,
            },
            route: {
              name: 'users',
              path: '/users',
              roles: [USER_ROLES.Admin],
              visible: true,
              icon: () => import('assets/icons/users.svg'),
              title: translate('users.user.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: userCardConfig,
            },
            route: {
              name: 'user',
              path: '/users/:id',
              link: '/users/new',
              roles: [USER_ROLES.Admin],
            },
          },
          /** Legal persons by user */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: legalPersonTableConfig,
            },
            route: {
              name: 'user-companies',
              path: '/users/:id/companies',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: legalPersonCardConfig,
            },
            route: {
              name: 'legal-person',
              path: '/legal-persons/:id',
              link: '/legal-persons/new',
              roles: [USER_ROLES.Admin],
            },
          },

          /** Clients */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clientTableConfig,
            },
            route: {
              name: 'clients',
              path: '/clients',
              roles: [USER_ROLES.Admin],
              visible: true,
              icon: () => import('assets/icons/briefcase.svg'),
              title: translate('clients.sidebar.table_title'),
            },
          },

          /** Orders */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: orderTableConfig,
            },
            route: {
              name: 'orders',
              path: '/orders',
              roles: [USER_ROLES.Admin],
              visible: true,
              icon: () => import('assets/icons/orders.svg'),
              title: translate('orders.sidebar.table_title'),
            },
          },

          /** BLOCK: CATALOG */
          /** Products */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: productTableConfig,
            },
            route: {
              name: 'products',
              path: '/catalog/products',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog.products.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: productCardConfig,
            },
            route: {
              name: 'product',
              path: '/catalog/products/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },
          /** Variations by user */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: variationTableConfig,
            },
            route: {
              name: 'product-variations',
              path: '/catalog/products/:id/variations',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: variationCardConfig,
            },
            route: {
              name: 'product-variation',
              path: '/variations/:id',
              link: '/variations/new',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
            },
          },

          /** Categories */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: categoryTableConfig,
            },
            route: {
              name: 'categories',
              path: '/catalog/categories',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog.categories.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: categoryCardConfig,
            },
            route: {
              name: 'category',
              path: '/catalog/categories/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },
          /** Brands */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: brandTableConfig,
            },
            route: {
              name: 'brands',
              path: '/catalog/brands',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog.brands.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: brandCardConfig,
            },
            route: {
              name: 'brand',
              path: '/catalog/brands/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },

          /** BLOCK: CATALOG IMPORT */
          /** Catalogs imports */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: productImportTableConfig,
            },
            route: {
              name: 'products-import',
              path: '/catalog-import/products',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog_imports.products.pages.sidebar_title'),
            },
          },
          /** Catalogs imports */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: categoryImportTableConfig,
            },
            route: {
              name: 'categories-import',
              path: '/catalog-import/categories',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog_imports.categories.pages.sidebar_title'),
            },
          },
          /** Brands imports */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: brandImportTableConfig,
            },
            route: {
              name: 'brands-import',
              path: '/catalog-import/brands',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('catalog_imports.brands.pages.sidebar_title'),
            },
          },

          /** BLOCK: MAPPINGS */
          /** Colors */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: baseColorsTableConfig,
            },
            route: {
              name: 'base-colors',
              path: '/mappings/base-colors',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('mappings.base_colors.pages.sidebar_title'),
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: baseColorsCardConfig,
            },
            route: {
              name: 'base-color',
              path: '/mappings/base-colors/:id',
              link: '/mappings/base-colors/new',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },

          /** Materials */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: baseMaterialsTableConfig,
            },
            route: {
              name: 'base-materials',
              path: '/mappings/base-materials',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('mappings.base_materials.pages.sidebar_title'),
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: baseMaterialsCardConfig,
            },
            route: {
              name: 'base-material',
              path: '/mappings/base-materials/:id',
              link: '/mappings/base-materials/new',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },

          /** Properties */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: propertiesTableConfig,
            },
            route: {
              name: 'mappings-properties',
              path: '/mappings/properties',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('mappings.properties.pages.sidebar_title'),
            },
          },

          /** BLOCK: CONTENT */
          /** Promotions */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: promotionsTableConfig,
            },
            route: {
              name: 'promotions',
              path: '/content/promotions',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.promotions.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: promotionsCardConfig,
            },
            route: {
              name: 'promotions-edit',
              path: '/content/promotions/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },
          /** News */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: newsTableConfig,
            },
            route: {
              name: 'news',
              path: '/content/news',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.news.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: newsCardConfig,
            },
            route: {
              name: 'news-edit',
              path: '/content/news/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },
          /** Blog */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: blogTableConfig,
            },
            route: {
              name: 'blog',
              path: '/content/blog',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.blog.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: blogCardConfig,
            },
            route: {
              name: 'blog-edit',
              path: '/content/blog/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },

          /** Requirements */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: requirementsTableConfig,
            },
            route: {
              name: 'requirements',
              path: '/content/requirements',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.requirements.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: requirementsCardConfig,
            },
            route: {
              name: 'requirements-edit',
              path: '/content/requirements/:id',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: false,
            },
          },

          /** Content pages */
          ...contentPageRoutes.routes,

          /** CONTACTS */
          ...contactsPageRoutes.routes,

          /** DELIVERY */
          ...deliveryPageRoutes.routes,

          /** ORDER INFO */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderInfoCardConfig,
            },
            route: {
              name: 'order-info',
              path: '/content/order-info',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.order_info.pages.sidebar_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderInfoCardConfig,
            },
            route: {
              name: 'order-info-msk',
              path: '/content/order-info/msk',
              roles: [USER_ROLES.Admin, USER_ROLES.AdminManager],
              visible: true,
              title: translate('content.order_info.pages.sidebar_title_msk'),
            },
          },

          /** SEO */
          /** STATIC PAGES */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: seoStaticTableConfig,
            },
            route: {
              name: 'seo-static',
              path: '/seo/static',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.seo.sidebar.static_table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: seoStaticCardConfig,
            },
            route: {
              name: 'seo-static-edit',
              path: '/seo/static/:id',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },
          /** DYNAMIC PAGES */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: seoDynamicTableConfig,
            },
            route: {
              name: 'seo-dynamic',
              path: '/seo/dynamic',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.seo.sidebar.dynamic_table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: seoDynamicCardConfig,
            },
            route: {
              name: 'seo-dynamic-edit',
              path: '/seo/dynamic/:id',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },

          /** BLOCK: SETTINGS */

          /** Banners */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bannerTableConfig,
            },
            route: {
              name: 'banners',
              path: '/settings/banners',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.banner.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: bannerCardConfig,
            },
            route: {
              name: 'banner',
              path: '/settings/banners/:id',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },

          /** Slider */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: sliderTableConfig,
            },
            route: {
              name: 'slider',
              path: '/settings/slider',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.slider.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: sliderCardConfig,
            },
            route: {
              name: 'slide',
              path: '/settings/slider/:id',
              link: '/settings/slider/new',
              roles: [USER_ROLES.Admin],
              visible: false,
            },
          },

          /** Footer categories */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: footerCategoriesCardConfig,
            },
            route: {
              name: 'footer-categories',
              path: '/settings/footer-categories/edit',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.footer_categories.pages.sidebar_title'),
            },
          },

          /** Min price order */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: minPriceOrderConfig,
            },
            route: {
              name: 'min-price-order',
              path: '/settings/min-price-order',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.min_price_order.pages.sidebar_title'),
            },
          },

          /** Footer categories */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: settingsImagesConfig,
            },
            route: {
              name: 'images',
              path: '/settings/images',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.images.pages.sidebar'),
            },
          },

          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: exportsTableConfig,
            },
            route: {
              name: 'exports',
              path: '/exports',
              roles: [USER_ROLES.Admin],
              visible: true,
              icon: () => import('assets/icons/exports.svg'),
              title: translate('exports.sidebar.table_title'),
            },
          },

          /** Prices */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: pricesConfig,
            },
            route: {
              name: 'prices',
              path: '/settings/prices',
              roles: [USER_ROLES.Admin],
              visible: true,
              title: translate('settings.prices.pages.sidebar_title'),
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
});

export default router;
