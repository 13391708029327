import type { UserList } from 'features/users/users';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import type { ClientList } from './types';

const BASE = '/api/admin/client';

export const read = request.table<ClientList>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `page=${activePage}&limit=${data.pagination.items || 25}`;

  const search = data.filters.title ? `&filters[0][id]=search&filters[0][value]=${data.filters.title}` : '';

  const sortId = data.sort.id ? `&sort[0][id]=id&sort[0][value]=${data.sort.id}` : '';
  const sortIdOneC = data.sort.idOneC ? `&sort[0][id]=idOneC&sort[0][value]=${data.sort.idOneC}` : '';
  const sortTitle = data.sort.title ? `&sort[0][id]=title&sort[0][value]=${data.sort.title}` : '';
  const sortCreatedAt = data.sort.created_at ? `&sort[0][id]=createdAt&sort[0][value]=${data.sort.created_at}` : '';
  const sortUpdatedAt = data.sort.updated_at ? `&sort[0][id]=updatedAt&sort[0][value]=${data.sort.updated_at}` : '';
  // ToDo: add more sort options

  return getListResponse<ClientList>(`${BASE}/list?${pagination}${search}${sortId}${sortIdOneC}${sortTitle}${sortCreatedAt}${sortUpdatedAt}`, activePage, null);
});
